import React from "react";

export const parseMardown = (text) => {
  return parseBold(text);
};

const parseBold = (text) => {
  if (!text) {
    return text;
  }

  const textArray = text.split("**");

  return textArray.map((item, idx) => {
    return idx % 2 === 0 ? item : <strong key={item}>{item}</strong>;
  });
};
