// extracted by mini-css-extract-plugin
export var item = "DesktopNav-module--item--1Fzka";
export var link = "DesktopNav-module--link--2mGei";
export var menu = "DesktopNav-module--menu--1vdgb";
export var menuButton = "DesktopNav-module--menuButton--3bGwc";
export var menuIcon = "DesktopNav-module--menuIcon--aeFK4";
export var menuIconLink = "DesktopNav-module--menuIconLink--3GyDv";
export var rowsWrapper = "DesktopNav-module--rowsWrapper--36pnM";
export var self = "DesktopNav-module--self--3F4ss";
export var subitem = "DesktopNav-module--subitem--2LzBi";
export var sublink = "DesktopNav-module--sublink--bT0Pe";
export var submenu = "DesktopNav-module--submenu--2Dosb";
export var upperRow = "DesktopNav-module--upperRow--wrRp9";