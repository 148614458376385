import React, { useState } from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Head from "./Head";
import Nav from "./Nav";
import Seo from "./seo";
import Footer from "../sections/Footer";
// import CookiesBar from "./CookiesBar";
import BrowseBar from "./BrowseBar";

import "../assets/css/reset.css";
import "../assets/scss/main.scss";

const Layout = ({
  children,
  seo,
  noindex,
  peopleOnlineWidget,
  withoutMenuBar,
}) => (
  <StaticQuery
    query={graphql`
      query {
        strapiHomepage {
          seo {
            metaTitle
            metaDescription
            shareImage {
              localFile {
                publicURL
              }
            }
          }
        }
        strapiFooter {
          contactNumber
          contactNumberText
          contactDetails
          email
          info
          logo {
            url
          }
        }
        strapiOnlinewidget {
          activated
          quantityMin
          quantityMax
          quantityCookieExpirationInMinutes
          dismissCookieExpirationInMinutes
          minHourToDisplay
          maxHourToDisplay
          hideAutomaticallyAfterXSeconds
        }
        allStrapiLanding {
          edges {
            node {
              slug
              title
              notVisibleInCourses
            }
          }
        }
        strapiGlobal {
          menuIconLinks {
            link
            iconFooter {
              localFile {
                publicURL
              }
              url
            }
          }
          googleOpinionsWidget
        }
      }
    `}
    render={(data) => {
      const [cookieAgreement, setCookieAgreement] = useState(false);
      const setAgreementCookie = () => {
        setCookieAgreement(true);
      };

      return (
        <>
          <Head noindex={noindex} />
          <Seo seo={seo} />
          {!withoutMenuBar && <Nav />}
          <main>{children}</main>
          {data.strapiOnlinewidget.activated && peopleOnlineWidget && (
            <BrowseBar
              data={data.strapiOnlinewidget}
              cookieAgreement={cookieAgreement}
            />
          )}
          {data.strapiGlobal.googleOpinionsWidget && (
            <>
              <div data-widget="101247" />
              <script src="https://api.ratingcaptain.com/widget_v3/js/b7f217f77df78abc6304de48076ecc5e" />
            </>
          )}
          <Footer data={data} />
        </>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
