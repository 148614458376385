import React from "react";

import clsx from "../../utils/clsx";
import { getMenuItems } from "./utils";
import * as styles from "./DesktopNav.module.scss";
import Image from "../Image";

const DesktopNav = ({
  className,
  logo,
  menuItems,
  strapiGlobal: { menuButtonText, menuButtonLink, menuIconLinks },
}) => {
  const shouldShowMenuButton = !!(menuButtonText && menuButtonLink);
  const shouldShowMenuIconLinks = !!(
    menuIconLinks &&
    !!menuIconLinks.length &&
    menuIconLinks[0].link
  );
  const shouldShowUpperRow = !!(
    shouldShowMenuButton || shouldShowMenuIconLinks
  );

  return (
    <div className={clsx(styles.self, className)}>
      {logo}
      <div className={styles.rowsWrapper}>
        {shouldShowUpperRow && (
          <div className={styles.upperRow}>
            {shouldShowMenuButton && (
              <a
                className={styles.menuButton}
                href={menuButtonLink}
                target="_blank"
                rel="nofollow"
              >
                {menuButtonText}
              </a>
            )}
            {shouldShowMenuIconLinks && (
              <div className={styles.menuIcons}>
                {menuIconLinks.map((item) => (
                  <a
                    className={styles.menuIconLink}
                    href={item.link}
                    target="_blank"
                    rel="nofollow"
                    key={item.link}
                  >
                    <Image className={styles.menuIcon} image={item.icon} />
                  </a>
                ))}
              </div>
            )}
          </div>
        )}
        <ul className={styles.menu}>{getMenuItems(menuItems, styles)}</ul>
      </div>
    </div>
  );
};

export default DesktopNav;
