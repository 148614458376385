import React from "react";
import { Helmet } from "react-helmet";

const Head = ({ noindex }) => {
  return (
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,400&display=swap"
        rel="stylesheet"
      />
      <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/3f087239642b43094d3509f1/script.js"></script>
      {noindex && <meta name="robots" content="noindex,follow" />}
    </Helmet>
  );
};

export default Head;
