import React from "react";
import { Link } from "gatsby";

export const getMenuItems = (menuItems, styles, closeMenuAction) => {
  return menuItems.map((item) => {
    return (
      <li key={item.slug} className={styles.item}>
        <Link to={item.slug} className={styles.link} onClick={closeMenuAction}>
          {item.title}
        </Link>
        {item.items && (
          <ul className={styles.submenu}>
            {item.items.map(({ node: { slug, title } }) => {
              return (
                <li key={slug} className={styles.subitem}>
                  <Link to={`/kurs/${slug}`} className={styles.sublink}>
                    {title}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </li>
    );
  });
};
