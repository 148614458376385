import React from "react";

const Image = ({
  image,
  className,
  type,
  alt = "",
  convertToJpg,
  convertToWebp,
}) => {
  if (!image) return null;

  let imageUrl;

  switch (type) {
    case "large":
      imageUrl = image?.formats?.large?.url;
      break;
    case "medium":
      imageUrl = image?.formats?.medium?.url;
      break;
    case "small":
      imageUrl = image?.formats?.small?.url;
      break;
    case "thumbnail":
      imageUrl = image?.formats?.thumbnail?.url;
      break;
    default:
      imageUrl = image.url;
      break;
  }

  if (!imageUrl) {
    imageUrl = image.url;
  }

  if (convertToJpg) {
    imageUrl = imageUrl.replace(/\.png$/, ".jpg");
  }

  if (convertToWebp) {
    const webpImageUrl = imageUrl.replace(/\.png$/, ".webp");

    return (
      <picture>
        <source type="image/webp" srcSet={webpImageUrl} />
        <source type="image/jpeg" srcSet={imageUrl} />
        <img src={imageUrl} className={className} loading="lazy" alt={alt} />
      </picture>
    );
  }

  return <img className={className} src={imageUrl} loading="lazy" alt={alt} />;
};

export default Image;
