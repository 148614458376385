import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import LogoImg from "../../images/logo-edugo.svg";
import MobileNav from "./MobileNav";
import DesktopNav from "./DesktopNav";

import * as styles from "./Nav.module.scss";

const Logo = () => (
  <div className={styles.logoContainer}>
    <Link className={styles.logoLink} to="/">
      <img className={styles.logo} src={LogoImg} alt="EduGo" />
    </Link>
  </div>
);

const getMenuItems = (allStrapiLanding, allStrapiPage) => {
  const pages = allStrapiPage.edges
    .sort((a, b) => a.node.menuOrder - b.node.menuOrder)
    .filter(({ node }) => node.visible)
    .map(({ node }) => {
      return {
        title: node.title,
        slug: `/${node.slug}`,
      };
    });

  return [
    {
      title: "O nas",
      slug: "/#o-nas",
    },
    {
      title: "Nasze kursy",
      slug: "/#kursy",
      items: allStrapiLanding.edges
        .filter(({ node }) => !node.notVisibleInCourses)
        .sort((a, b) => a.node.menuOrder - b.node.menuOrder),
    },
    ...pages,
    {
      title: "Kontakt",
      slug: "/#kontakt",
    },
  ];
};

const Nav = () => {
  const { allStrapiLanding, allStrapiPage, strapiGlobal } =
    useStaticQuery(query);
  const menuItems = getMenuItems(allStrapiLanding, allStrapiPage);

  return (
    <nav className={styles.self}>
      <MobileNav
        className={styles.mobileMenu}
        logo={<Logo />}
        menuItems={menuItems}
        strapiGlobal={strapiGlobal}
      />

      <DesktopNav
        className={styles.desktopMenu}
        logo={<Logo />}
        menuItems={menuItems}
        strapiGlobal={strapiGlobal}
      />
    </nav>
  );
};

export default Nav;

const query = graphql`
  query {
    strapiGlobal {
      menuButtonText
      menuButtonLink
      menuIconLinks {
        link
        icon {
          localFile {
            publicURL
          }
          url
        }
        iconMobileMenu {
          localFile {
            publicURL
          }
          url
        }
      }
    }
    allStrapiLanding {
      edges {
        node {
          menuOrder
          title
          slug
          strapiId
          notVisibleInCourses
        }
      }
    }
    allStrapiPage {
      edges {
        node {
          menuOrder
          title
          slug
          strapiId
          visible
        }
      }
    }
  }
`;
