import React, { useEffect, useState } from "react";

import * as styles from "./BrowseBar.module.scss";
import Cross from "../../images/menu-cross.svg";
import { getCookie, setCookie } from "../../utils/cookies";

const QUANTITY = "QUANTITY";
const BROWSE_BAR_DISMISSED = "BROWSE_BAR_DISMISSED";

function getRandom(minRand, maxRand) {
  return (Math.floor(Math.random() * 10) % (maxRand + 1 - minRand)) + minRand;
}

const BrowseBar = ({ cookieAgreement = false, data }) => {
  const {
    quantityMin: minRand, // 9
    quantityMax: maxRand, // 16
    quantityCookieExpirationInMinutes: quantityCookieTime, // 15
    dismissCookieExpirationInMinutes: dismissCookieTime, // 30
    minHourToDisplay: displayMinHour, // 8
    maxHourToDisplay: displayMaxHour, // 21
    hideAutomaticallyAfterXSeconds, // 60
  } = data;

  const onClick = () => {
    setCookie(BROWSE_BAR_DISMISSED, true, dismissCookieTime);
    setIsBarDismissed(true);
  };

  const [quantity, setQuantity] = useState(0);
  const [isBarDismissed, setIsBarDismissed] = useState(false);

  useEffect(() => {
    setIsBarDismissed(getCookie(BROWSE_BAR_DISMISSED));
  }, []);

  useEffect(() => {
    let quantity = getCookie(QUANTITY);

    if (!quantity) {
      quantity = getRandom(minRand, maxRand);
      setCookie(QUANTITY, quantity, quantityCookieTime);
    }

    setQuantity(quantity);
  }, []);

  // hide automatically after some time (should work like onClick)
  useEffect(() => {
    setTimeout(() => {
      onClick();
    }, hideAutomaticallyAfterXSeconds * 1000);
  }, []);

  const time = new Date();
  const isCorrectTime =
    time.getHours() >= displayMinHour && time.getHours() <= displayMaxHour;

  const shouldShowBanner =
    cookieAgreement && quantity && !isBarDismissed && isCorrectTime;

  return shouldShowBanner ? (
    <div className={styles.self}>
      <button onClick={onClick} className={styles.button}>
        <img src={Cross} />
      </button>
      <div className={styles.inner}>
        <p className={styles.text}>
          Tę stronę przegląda obecnie&nbsp;
          <span className={styles.quantity}>{quantity}</span>
          &nbsp;osób
        </p>
      </div>
    </div>
  ) : null;
};

export default BrowseBar;
