// extracted by mini-css-extract-plugin
export var checked = "MobileNav-module--checked--3mp_I";
export var link = "MobileNav-module--link--lwQU6";
export var menu = "MobileNav-module--menu--kkVWC";
export var menuButton = "MobileNav-module--menuButton--1oKes";
export var menuIcon = "MobileNav-module--menuIcon--15B5n";
export var menuIconLink = "MobileNav-module--menuIconLink--1dB1q";
export var menuIcons = "MobileNav-module--menuIcons--noT8_";
export var menuOpened = "MobileNav-module--menuOpened--A6xii";
export var menuToggle = "MobileNav-module--menuToggle--yVvSa";
export var self = "MobileNav-module--self--2OVP9";
export var subitem = "MobileNav-module--subitem--2U2mf";
export var sublink = "MobileNav-module--sublink--2kZvd";