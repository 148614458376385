// extracted by mini-css-extract-plugin
export var bottomContainer = "Footer-module--bottomContainer--3NFh3";
export var columnsWrapper = "Footer-module--columnsWrapper--QpMXA";
export var contactColumn = "Footer-module--contactColumn--3G-2s";
export var contactDetails = "Footer-module--contactDetails--3ZYTR";
export var contactSection = "Footer-module--contactSection--2XicZ";
export var desktopLogo = "Footer-module--desktopLogo--hwBOw";
export var email = "Footer-module--email--2LLVi";
export var info = "Footer-module--info--4sfdC";
export var inner = "Footer-module--inner--2cZMc";
export var logo = "Footer-module--logo--Eanir";
export var menuIcon = "Footer-module--menuIcon--2kmhJ";
export var menuIconLink = "Footer-module--menuIconLink--2Ohor";
export var mobileLogo = "Footer-module--mobileLogo--11KNE";
export var pageLink = "Footer-module--pageLink--3emwx";
export var pageList = "Footer-module--pageList--M3raH";
export var pageListHeader = "Footer-module--pageListHeader--3PCgq";
export var phoneNumber = "Footer-module--phoneNumber--Ets6X";
export var privacyPolicy = "Footer-module--privacyPolicy--74XkQ";
export var protectionStandards = "Footer-module--protectionStandards--1AhXN";
export var self = "Footer-module--self--1K87S";
export var socialsContainer = "Footer-module--socialsContainer--2M0Ht";