import React, { useState } from "react";

import * as styles from "./MobileNav.module.scss";
import clsx from "../../utils/clsx";
import { getMenuItems } from "./utils";
import Image from "../Image";

const MobileNav = ({
  className,
  logo,
  menuItems,
  strapiGlobal: { menuButtonText, menuButtonLink, menuIconLinks },
}) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const shouldShowMenuButton = !!(menuButtonText && menuButtonLink);
  const shouldShowMenuIconLinks = !!(
    menuIconLinks &&
    !!menuIconLinks.length &&
    menuIconLinks[0].link
  );

  const toggleMenu = () => {
    setMenuOpened(!menuOpened);
  };

  return (
    <div className={clsx(styles.self, className)}>
      <div className={styles.menuToggle}>
        <input
          type="checkbox"
          onClick={toggleMenu}
          className={menuOpened ? styles.checked : undefined}
        />
        <span />
        <span />
        <span />
        <div className={clsx(styles.menu, menuOpened && styles.menuOpened)}>
          <ul>
            {getMenuItems(menuItems, styles, toggleMenu)}
            {shouldShowMenuButton && (
              <a
                className={styles.menuButton}
                href={menuButtonLink}
                target="_blank"
                rel="nofollow"
              >
                {menuButtonText}
              </a>
            )}
          </ul>
          {shouldShowMenuIconLinks && (
            <div className={styles.menuIcons}>
              {menuIconLinks.map((item) => (
                <a
                  className={styles.menuIconLink}
                  href={item.link}
                  target="_blank"
                  rel="nofollow"
                  key={item.link}
                >
                  <Image
                    className={styles.menuIcon}
                    image={item.iconMobileMenu}
                  />
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
      {logo}
    </div>
  );
};

export default MobileNav;
